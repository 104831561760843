import { useState } from 'react'
import { FiSave, FiX } from 'react-icons/fi'


import MarkdownEditor from '../Markdown';
import Button from '../ui/Button';
import axios from 'axios';



export default function EditStory({ markdown,
    setIsEditing,
    setStory,

    storyId,
    story
}) {

  const [value, setValue] =useState(markdown);
  const [updating, setUpdating] = useState(false);
  const updateAPICall = async () => {
    setUpdating(true);
    const previousValue = story;
    setStory(
        (prev) => ({
            ...prev,
            description: value,
        }),
    );
    try {
        const response = await axios.put(
            `${process.env.REACT_APP_BACKEND_URL}/api/story/${storyId}`,
            {
                description: value,
            },
            {
            
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            },
          );
            if (response.data.success) {
                setIsEditing(false);
            }
            else{
                setStory((prev) => ({
                    ...prev,
                    description: previousValue,
                }));
            }
    } catch (error) {
        setStory((prev) => ({
            ...prev,
            description: previousValue,
        }));
    } finally {
        setUpdating(false);
    }
  }
  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex justify-end items-center p-4">
        <div className="flex space-x-2">
        <Button
                onClick={() => {
                  updateAPICall();
                }}
                loading={updating}
                className="flex items-center px-3 py-2 bg-black text-white"
              >
                <FiSave className="mr-2" /> Save
              </Button>
              <Button
                onClick={() => setIsEditing(false)}
                className="flex items-center px-3 py-2 bg-darkergray text-white"
              >
                <FiX className="mr-2" /> Cancel
              </Button>
        </div>
      </div>
      <MarkdownEditor
          value={value}
            setValue={setValue}
          />
      
    </div>
  )
}