// src/App.js
import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Register from "./components/Register/Register";
import Login from "./components/Login/Login";
import ChatbotPage from "./components/ChatbotPage/ChatbotPage";
import Navbar from "./components/Navbar/Navbar";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import ProtectedRoute from "./utils/ProtectedRoute";
import VerifyEmail from "./components/VerifyEmail/VerifyEmail";
import Checkout from "./components/Checkout/Checkout";
import { Home } from "./components/Home/Home";
import AuthProvider from "./context/AuthContext";
import OrderSuccess, {
  PackageOrderSuccess,
  StoryOrderSuccess,
} from "./components/Order/OrderSuccess";
import Story from "./components/Story/Story";
import NotFound from "./components/NotFound/NotFound";
import TurnStory from "./components/Film/TurnStory";
import SingleStory from "./components/Story/SingleStory";
import Film from "./components/Film/Film";
import SingleTurnStory from "./components/Film/SingleTurnStory";
import ContinueStory from "./components/ContinueStory/ContinueStory";
import Product from "./components/Product/Product";
import Order from "./components/Order/Order";
import ContactUs from "./components/Contact/Contact";
import FeaturedStory from "./components/FeaturedStory/FeaturedStory";
import SingleFeaturedStory from "./components/FeaturedStory/SingleFeaturedStory";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import { CacheProvider } from "./CacheContext";
import PricingPage from "./components/PricePage";

const SaveLastVisitedPage = () => {
  const location = useLocation();

  useEffect(() => {
    const restrictedPaths = ["/login", "/register", "/forgot-password"];
    const isRestrictedPath =
      restrictedPaths.includes(location.pathname) ||
      location.pathname.startsWith("/reset-password") ||
      location.pathname.startsWith("/verify-email");

    if (!isRestrictedPath) {
      console.log("Saving last visited page:", location.pathname);
      localStorage.setItem("lastVisitedPage", location.pathname);
    }
  }, [location]);

  return null;
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  return (
    <CacheProvider>
      <AuthProvider>
        <SaveLastVisitedPage />
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/" element={<Home />} />
          <Route path="/pricing" element={<PricingPage />} />

          
          <Route path="/start-your-story" element={<ChatbotPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/verify-email/:token" element={<VerifyEmail />} />
          <Route
            path="/turn-your-story-into-a-film"
            element={
              <ProtectedRoute>
                <TurnStory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/turn-your-story-into-a-film/:id"
            element={
              <ProtectedRoute>
                <SingleTurnStory />
              </ProtectedRoute>
            }
          />
          <Route
            path="/film"
            element={
              <ProtectedRoute>
                <Film />
              </ProtectedRoute>
            }
          />
          <Route
            path="/checkout"
            element={
              <ProtectedRoute>
                <Checkout />
              </ProtectedRoute>
            }
          />
          <Route
            path="/order-story-success"
            element={
              <ProtectedRoute>
                <StoryOrderSuccess />
              </ProtectedRoute>
            }
          />
          <Route
            path="/order-package-success"
            element={
              <ProtectedRoute>
                <PackageOrderSuccess />
              </ProtectedRoute>
            }
          />
          <Route
            path="/story"
            element={
              <ProtectedRoute>
                <Story />
              </ProtectedRoute>
            }
          />
          <Route
            path="/story/:id"
            element={
              <ProtectedRoute>
                <SingleStory />
              </ProtectedRoute>
            }
          />
          <Route path="/continue-your-story" element={<ContinueStory />} />
          <Route
            path="/product/:id"
            element={
              <ProtectedRoute>
                <Product />
              </ProtectedRoute>
            }
          />
          <Route
            path="/order"
            element={
              <ProtectedRoute>
                <Order />
              </ProtectedRoute>
            }
          />

          <Route
            path="/change-password"
            element={
              <ProtectedRoute>
                <ChangePassword />
              </ProtectedRoute>
            }
          />

          <Route path="/featured-stories" element={<FeaturedStory />} />
          <Route path="/featured-story/:id" element={<SingleFeaturedStory />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </AuthProvider>
      </CacheProvider>
  );
};

export default App;
