import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useCalendlyEventListener, InlineWidget } from "react-calendly";
import { useCache } from "../../CacheContext";

const SingleTurnStory = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const [calendlyHeight, setCalendlyHeight] = useState("700px");

  const { getCacheData, setCacheData, clearCacheData } = useCache();
  const [story, setStory] = useState(
    () => getCacheData(`turnStory_${id}`) || null
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/story/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        console.log("single turn story api call made");
        setStory(response.data.data);
        setCacheData(`turnStory_${id}`, response.data.data);
        setLoading(false);
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred while fetching the story.";
        toast.error(errorMessage);
        setLoading(false);
      }
    };

    if (!story) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [id, story, setCacheData]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/auth/user`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setUser(response.data.data);
      })
      .catch((error) => {
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred while fetching the user.";
        toast.error(errorMessage);
      });
  }, []);

  const createOrder = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/order`,
        {
          productName: "Film",
          totalAmount: 1250,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      clearCacheData("orders");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
    }
  };

  const updateStory = async () => {
    try {
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/story/${id}`,
        {
          isFilm: true,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      clearCacheData("stories");
      clearCacheData("films");
      clearCacheData("turnStories");
      clearCacheData(`turnStory_${id}`);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
    }
  };

  useCalendlyEventListener({
    onPageHeightResize: (e) => setCalendlyHeight(e.data.payload.height),
    onEventScheduled: (e) => {
      createOrder();
      updateStory();
    },
  });

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <i className="fa-solid fa-spinner animate-spin text-2xl"></i>
      </div>
    );
  }

  if (!story) {
    return (
      <div className="h-screen flex items-center justify-center">
        <p>No story found</p>
      </div>
    );
  }

  if (story.isFilm == true) {
    return (
      <div className="h-screen flex items-center justify-center">
        <p>This story is already converted into a film</p>
      </div>
    );
  }

  const toggleStoryContent = () => {
    setOpen(!open);
  };

  return (
    <div className="container mx-auto flex items-center justify-center flex-col space-y-2 relative px-4 py-8">
      <ToastContainer />

      <div className="flex flex-col gap-16 sm:gap-0 sm:flex-row w-full justify-center">
        <video
          controls
          autoPlay
          muted
          playsInline
          preload="auto"
          className="max-h-[600px] mb-4"
        >
          <source
            src="https://melo-story.s3.eu-north-1.amazonaws.com/DRAFT+4+(1)+(1)+(2).mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        <InlineWidget
          // url="https://calendly.com/sufiyanirfan1613/free-consultation"
          url="https://calendly.com/hellomelostorytelling/chapter-one-film-photo-package-clone"
          prefill={{
            email: user?.email,
            name: user?.name,
          }}
          styles={{ height: calendlyHeight, width: "100%" }}
          pageSettings={{
            hideGdprBanner: true,
            backgroundColor: "ffffff",
            primaryColor: "000000",
            textColor: "000000",
          }}
        />
      </div>

      <div className="max-w-4xl mx-auto border border-gray-200 shadow-sm p-4 rounded w-full">
        <div className="flex items-center w-full">
          <div className="flex flex-row justify-between w-full">
            <h4 className="font-semibold text-gray-900">{story.name}</h4>
            <div className="flex items-center justify-center">
              <button onClick={() => toggleStoryContent()}>
                {open ? (
                  <i class="fa-solid fa-circle-chevron-up"></i>
                ) : (
                  <i class="fa-solid fa-circle-chevron-down"></i>
                )}
              </button>
            </div>
          </div>
        </div>
        {open && (
          <div className="mt-4 p-4 border-t border-gray-200">
            <div dangerouslySetInnerHTML={{ __html: story.description }} />
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleTurnStory;
