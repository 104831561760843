const PrivacyPolicy = () => (
  <div className="max-w-4xl mx-auto px-4 py-8">
    <h2>Terms and Conditions / Privacy Statement</h2>

    <h4>Introduction</h4>
    <p>
      Welcome to{" "}
      <a href="https://melostories.com.au/" className="underline text-blue-600">
        melostories.com.au
      </a>
      . By accessing or using our website, you agree to comply with and be bound
      by the following terms and conditions. Please review them carefully.
    </p>

    <h4>Use of the Website</h4>
    <ol>
      <li>
        <strong className="font-semibold">Eligibility:</strong> By using our
        website, you affirm that you are at least 18 years old and are legally
        able to enter into this agreement.
      </li>
      <li>
        <strong className="font-semibold">Account Registration:</strong> To
        access certain features of our website, you must register for an account
        by providing your email and creating a password. You are responsible for
        maintaining the confidentiality of your account information and for all
        activities that occur under your account.
      </li>
      <li>
        <strong className="font-semibold">User Data:</strong> Our website uses
        the LLM API to ask questions about your personal life and business. This
        information is collected to help us create a personalized story for you.
        We do not share your personal data with third parties.
      </li>
    </ol>

    <h4>Privacy Statement</h4>
    <ol>
      <li>
        <strong className="font-semibold">Data Collection:</strong> We collect
        personal information that you provide to us when registering for an
        account, including your email address and password, as well as any
        information shared during the story creation process.
      </li>
      <li>
        <strong className="font-semibold">Use of Information:</strong> The
        information collected is used solely to create your personalized story
        and to facilitate the purchase and delivery of this story.
      </li>
      <li>
        <strong className="font-semibold">Data Security:</strong> We implement
        appropriate security measures to protect your personal information from
        unauthorized access, disclosure, alteration, or destruction.
      </li>
      <li>
        <strong className="font-semibold">Data Sharing:</strong> We do not share
        your personal data with third parties, except as required by law or to
        protect our rights.
      </li>
      <li>
        <strong className="font-semibold">Data Retention:</strong> We retain
        your personal information for as long as necessary to fulfill the
        purposes for which it was collected and to comply with legal
        obligations.
      </li>
    </ol>

    <h4>Purchases</h4>
    <ol>
      <li>
        <strong className="font-semibold">Order Process:</strong> By placing an
        order on our website, you agree to provide accurate and complete
        information. All orders are subject to acceptance and availability.
      </li>
      <li>
        <strong className="font-semibold">Payment:</strong> Payments are
        processed through secure payment gateways. We do not store your payment
        information.
      </li>
      <li>
        <strong className="font-semibold">Delivery:</strong> Your personalized
        story will be available on the site and saved in your account.
        Additionally, a copy will be sent to the email address provided during
        registration. From time to time, we may send you additional information
        to your email address.
      </li>
    </ol>

    <h4>Changes to Terms and Conditions / Privacy Statement</h4>
    <p>
      We reserve the right to update or modify these terms and conditions and
      our privacy statement at any time without prior notice. Your continued use
      of the website after any changes indicates your acceptance of the new
      terms.
    </p>

    <h4>Contact Us</h4>
    <p>
      If you have any questions about these Terms and Conditions / Privacy
      Statement, please contact us at
      <a
        href="mailto:hello@hellomelo.com.au"
        className="underline text-blue-600"
      >
        {" "}
        hello@hellomelo.com.au
      </a>
      .
    </p>
  </div>
);
export default PrivacyPolicy;
