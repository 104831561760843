import React, { useEffect, useState, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import "./Chatbot.css";
const Chatbot = ({ setStep,setFinalMessg,prompt,finalMessgKey,stepKey}) => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const messagesContainerRef = useRef(null);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (messages.length > 0) {
      localStorage.setItem(prompt, JSON.stringify(messages));
    }
  }, [messages]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    resizeTextarea();
  }, [inputValue]);

  const chatgptAPICall = async (message, previousMessages) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/chat`,
        {
          userMessage: message,
          chatHistory: previousMessages,
          prompt: prompt,
        }
      );

      if (response.data.success) {
        return response.data.message;
      }
    } catch (error) {
      const errorMessage =
      error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
    }
  };
  const initializeChat = async () => {
    setIsTyping(true);
    const response= await chatgptAPICall("Generate first question", []);
      if (!response) {
        setIsTyping(false);
        throw new Error("Error in response");
      }
      setMessages([
        {
          role: "system",
          content: response,
        },
      ]);
      setIsTyping(false);
  }
  useEffect(() => {
    setMessages([]);
    const savedMessages = localStorage.getItem(prompt);
    if (savedMessages) {
      setMessages(JSON.parse(savedMessages));
    } else {
      initializeChat();
    }
  }, [prompt]);
  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;
    }
  };

  const handleSend = async () => {
    if (!inputValue.trim()) {
      toast.error("Please type something");
      return;
    }

    setIsTyping(true);
    const userMessage = { role: "user", content: inputValue };
    setMessages([...messages, userMessage]);
    setInputValue("");

    try {
      const botResponseText = await chatgptAPICall(inputValue, messages);
      if (!botResponseText) {
        throw new Error("Error in response");
      }

      setIsTyping(false);
      const botResponse = { role: "system", content: "" };
      setMessages((prevMessages) => {
        const newMessages = [...prevMessages, botResponse];
        typewriterEffect(botResponseText, newMessages.length - 1);
        return newMessages;
      });
    } catch (error) {
      const errorMessage =
      error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
      setIsTyping(false);
    }
  };

  const generateStory = async () => {
    if (messages.length < 7) {
      toast.error(
        "Please have atleast 3 conversations to generate a better story"
      );
      return;
    }
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/story`,
        {
          userMessage: `
            Please write a story based on the conversation attached. Use only the specified HTML tags for formatting the output. Ensure that the text is well-structured and formatted clearly with the following HTML tags:
      
            - <h4> for headings
            - <p> for paragraphs
         
            Ensure the story follows the structure provided. Do not use bold formatting for headings; instead, use the appropriate HTML tags as specified.`,
          chatHistory: messages,
          prompt:'GENERATE_STORY_PROMPT'
        }
      );

      if (response.data.success) {
        const result = response.data.message;
        localStorage.setItem(`introStoryLines_${prompt}`, response.data.introStory);
        setFinalMessg(result);
        setLoading(false);
        setStep(2);
        localStorage.setItem(stepKey, 2);
        localStorage.setItem(finalMessgKey, result);

      }
    } catch (error) {
      const errorMessage =
      error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSend();
    }
  };

  const typewriterEffect = (text, index) => {
    let charIndex = -1;
    const interval = setInterval(() => {
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        if (updatedMessages[index].content.length < text.length) {
          updatedMessages[index].content += text.charAt(charIndex);
        }
        return updatedMessages;
      });
      charIndex++;
      if (charIndex === text.length) {
        clearInterval(interval);
      }
    }, 20);
  };

  const resizeTextarea = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      const scrollHeight = textarea.scrollHeight;
      const lineHeight = parseInt(
        window.getComputedStyle(textarea).lineHeight,
        10
      );
      const rows = Math.floor(scrollHeight / lineHeight);
      const maxRows = 5;

      if (rows > maxRows) {
        textarea.style.height = `${lineHeight * maxRows}px`;
        textarea.style.overflowY = "auto";
      } else {
        textarea.style.height = `${scrollHeight}px`;
        textarea.style.overflowY = "hidden";
      }
    }
  };

  const handleStartAgain = () => {
    setMessages([]);
    localStorage.removeItem(prompt);
    initializeChat();
  };

  return (
    <div className="w-full h-full flex flex-col justify-between">
      <ToastContainer />
      <div className="max-w-4xl mx-auto flex flex-col flex-grow mb-2">
        <div className="p-2 m-0 w-full rounded-lg mt-4">
          <div
            id="msg"
            ref={messagesContainerRef}
            className="enhanced-textarea overflow-y-auto p-3 pl-0 pb-12"
          >
            {messages.map((message, index) => (
              <div
                key={index}
                className={`mb-4 ${
                  message.role === "system" ? "text-left" : "text-right"
                }`}
              >
                <span
                  className={`inline-block p-3 max-w-[80%] rounded-lg ${
                    message.role === "system"
                      ? "bg-white rounded-br-none"
                      : "text-start bg-gray-200 rounded-bl-none"
                  }`}
                >
                  <pre>{message.content}</pre>
                </span>
              </div>
            ))}

            {isTyping && (
              <div className="text-left mb-2">
                <span className="inline-block p-3 max-w-[80%] bg-gray-200 rounded-lg animate-pulse">
                  Typing...
                </span>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="max-w-4xl mx-auto fixed bottom-20 left-0 right-0 w-full bg-white p-2 border-t border-gray-200">
        

        <div className="pb-2  flex gap-2 items-end justify-center">
          <textarea
            ref={textareaRef}
            value={inputValue}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Type here..."
            className="flex-grow p-2 bg-white border border-gray-400 rounded-md outline-none focus:outline-none resize-none overflow-y-hidden"
            rows="1"
          />
          <button
            onClick={handleSend}
            className="bg-black text-white rounded-md px-4 py-2"
            disabled={false}
          >
            <i className="fa-solid fa-location-arrow"></i>
          </button>
        </div>
        <div className="flex gap-4 justify-center">
          <button
            onClick={handleStartAgain}
            className="rounded-md transition-all bg-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 "
            disabled={isTyping || loading}
          >
            Start again
          </button>
          <button
            onClick={generateStory}
            className="rounded-md transition-all bg-black px-3 py-2 font-semibold text-white shadow-sm hover:opacity-75 text-sm"
            disabled={isTyping || loading}
          >
            {loading ? (
              <i className="fa-solid fa-spinner animate-spin"></i>
            ) : (
              "I'm done, write my story"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Chatbot;
