// ForgotPassword.js
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(() => {
    const savedTimer = localStorage.getItem("timer");
    return savedTimer ? JSON.parse(savedTimer) : 0;
  });

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/forgot-password`,
        data
      );
      toast.success(res.data.message);
      setLoading(false);
      setTimer(60); // Set timer for 60 seconds
      localStorage.setItem("timer", JSON.stringify(60));
      localStorage.setItem("timerStart", Date.now());
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
      setLoading(false);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const savedTimerStart = localStorage.getItem("timerStart");
      if (savedTimerStart) {
        const elapsed = Math.floor((Date.now() - savedTimerStart) / 1000);
        const newTimer = 60 - elapsed;
        if (newTimer <= 0) {
          setTimer(0);
          localStorage.removeItem("timer");
          localStorage.removeItem("timerStart");
        } else {
          setTimer(newTimer);
          localStorage.setItem("timer", JSON.stringify(newTimer));
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="py-8 px-4 flex items-center justify-center">
      <ToastContainer />
      <form
        className="bg-gray-100 p-8 rounded-md w-full max-w-md"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h2 className="text-center">Forgot Password</h2>
        <div className="mb-4">
          <input
            type="email"
            {...register("email", {
              required: "Email is required",
              pattern: { value: /^\S+@\S+$/i, message: "Invalid email" },
            })}
            className="w-full py-2 px-3 border border-gray-300 rounded outline-none focus:outline-none"
            placeholder="Email"
          />
          {errors.email && (
            <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>
          )}
        </div>

        <button
          type="submit"
          className="mt-2 w-full bg-black text-white py-2.5 px-3 rounded"
          disabled={loading || timer > 0}
        >
          {loading ? (
            <i className="fa-solid fa-spinner animate-spin"></i>
          ) : timer > 0 ? (
         `Didn't receive the email? Try again in ${timer}s`
          ) : (
            "Send Reset Link"
          )}
        </button>
      </form>
    </div>
  );
};

export default ForgotPassword;
