import React, { useState } from "react";
import { BsChevronUp, BsChevronDown } from "react-icons/bs";

const ContentStrategySection = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <section>
      <h3 className="text-center mb-5 font-playfair font-bold">CONTENT STRATEGY</h3>
      <p className=" text-start font-lato">
      Created directly from your story and values, designed to connect with your audience.
      </p>
      <div className="flex items-center justify-center cursor-pointer mb-2">
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-xs flex items-center gap-2"
        >
          <span>{!isExpanded ? "VIEW EXAMPLE" : "HIDE EXAMPLE"}</span>
          {isExpanded ? (
            <BsChevronUp className="ml-1 w-4 h-4" />
          ) : (
            <BsChevronDown className="ml-1 w-4 h-4" />
          )}
        </button>
      </div>

      <div className="space-y-6">
        {isExpanded && (
          <div className="animate-fadeIn space-y-6">
            <div className="space-y-4">
              <h4>Day 1: Introduction to Your Journey</h4>
              <div className="space-y-2">
                <p>Post: A photo of you as a young designer.</p>
                <p>
                  Caption: "People often assume I had a smooth journey to
                  becoming a designer, but it's been anything but. My childhood
                  was full of challenges, but it's also where my love for
                  creativity and resilience began."
                </p>
              </div>
            </div>

            <div className="space-y-4">
              <h4>Day 2: Resilience in Childhood</h4>
              <div className="space-y-2">
                <p>
                  Post: Childhood photo or a nostalgic image that represents
                  your upbringing.
                </p>
                <p>
                  Caption: "Growing up in Campbelltown, life was tough. My
                  brother's illness made home chaotic, and my mum, a single
                  parent, did her best with what little we had. Those
                  experiences taught me resilience from a young age."
                </p>
              </div>
            </div>

            <div className="space-y-4">
              <h4>Day 3: Creative Beginnings</h4>
              <div className="space-y-2">
                <p>Post: A picture of fabric or a simple sketch.</p>
                <p>
                  Caption: "My escape was always creativity. As a kid, I'd take
                  any fabric my mum could spare and design something new. My mum
                  didn't fully understand, but she supported me. It was the
                  beginning of a lifelong passion."
                </p>
              </div>
            </div>

            <div className="space-y-4">
              <h4>Day 4: Embracing a Different Path</h4>
              <div className="space-y-2">
                <p>Post: Photo of TAFE or a reflective moment.</p>
                <p>
                  Caption: "I didn't take the traditional school path, but the
                  look took me to TAFE. It wasn't prestigious, but it was where
                  I found my place and the skills to start sewing dresses from
                  my bedroom. Sometimes, the right path is the one you least
                  expect."
                </p>
              </div>
            </div>

            <div className="space-y-4">
              <h4>Day 5: Connection with Clients (Empathy)</h4>
              <div className="space-y-2">
                <p>Post: Picture of a wedding dress or a happy bride.</p>
                <p>
                  Caption: "Dressing women on their big day is more than a
                  job—it's about understanding their dreams and making them feel
                  special. Every gown I create has its purpose at heart, shaped
                  by the love and support I found along my journey."
                </p>
              </div>
            </div>

            <div className="space-y-4">
              <h4>Day 6: Recognizing Mum's Role (Self-Worth & Gratitude)</h4>
              <div className="space-y-2">
                <p>
                  Post: Photo of your mum or a childhood photo that brings her
                  presence into the story.
                </p>
                <p>
                  Caption: "My mum always believed in me, even if she didn't
                  fully understand my dreams. Today, she proudly tells everyone,
                  'Steven Khalil is my son!' She's my biggest cheerleader and a
                  reminder that my journey began."
                </p>
              </div>
            </div>

            <div className="space-y-4">
              <h4>Day 7: Reflection on the Journey (Resilience, Self-Worth)</h4>
              <div className="space-y-2">
                <p>Post: Reflective photo of you in your workspace.</p>
                <p>
                  Caption: "Nothing in my life was meant to be easy, but every
                  challenge shaped me into who I am today. I wouldn't change a
                  thing, because each step brought me closer to doing what I
                  love and finding my place in the world."
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default ContentStrategySection;
