import React from 'react';
import { useNavigate } from 'react-router-dom';

const PricingCard = ({ title, price, frequency, features, buttonText, color,url }) => {
  const navigate=useNavigate()
  return (
    <div className=" mb-24 text-start md:text-center mx-auto container">
      {title && <h1 className="text-3xl text-center mb-5 font-playfair font-semibold">{title}</h1>}
      <div className="mb-2">
        <h1 className="text-4xl font-semibold font-playfair mb-0 text-center">${price}</h1>
        {frequency && <p className="text-sm text-gray-600 block font-playfair text-center">{frequency}</p>}
      </div>
      
      <div
      className='px-8 ml-2'
      >
      <div className="space-y-3 mb-6">
        {features.map((feature, index) => (
          <p key={index} className="text-gray-600">{feature}</p>
        ))}
      </div>
      
     
      </div><div className=' mt-10 flex justify-center'>
     <button 
     onClick={
        ()=>{
          if(url){
            navigate(url)
          }
        }
     }
        className={` font-lato px-5 py-3 text-sm  ${
          color === 'not-black' 
            ? 'bg-gray-200 text-gray-600'
            : 'bg-black text-white'
        }`}
      >
        {buttonText}
      </button>
     </div>
    </div>
  );
};
export default PricingCard