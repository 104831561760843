// CacheContext.js
import React, { createContext, useState, useContext } from "react";

const CacheContext = createContext();

export const CacheProvider = ({ children }) => {
  const [cache, setCache] = useState({});

  const setCacheData = (key, data) => {
    setCache((prevCache) => ({ ...prevCache, [key]: data }));
  };

  const getCacheData = (key) => {
    return cache[key];
  };

  const clearCacheData = (key) => {
    setCache((prevCache) => {
      const newCache = { ...prevCache };
      delete newCache[key];
      return newCache;
    });
  };

  return (
    <CacheContext.Provider value={{ setCacheData, getCacheData, clearCacheData }}>
      {children}
    </CacheContext.Provider>
  );
};

export const useCache = () => {
  return useContext(CacheContext);
};
