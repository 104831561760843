import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";

const VerifyEmail = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);

  const verifyEmail = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/verify-email/${token}`
      );
      toast.success(res.data.message); // Adjusted the key to match the backend response
      setIsVerified(true);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
      setIsVerified(false);
    }
  };

  useEffect(() => {
    if (token) {
      verifyEmail();
    }
  }, [token]);

  const handleLoginClick = () => {
    navigate("/login");
  };

  return (
    <div className="py-8 px-4 flex items-center justify-center">
      <ToastContainer />
      {isVerified ? (
        <div className="flex items-center flex-col">
          <img
            src="https://melo-story.s3.eu-north-1.amazonaws.com/check-mark.png"
            alt="Verification Success"
            className="mb-4"
          />
          <h2>Email verified successfully!</h2>
          <button
            onClick={handleLoginClick}
            className="bg-black text-white rounded-md py-2 px-4"
          >
            Go to Login
          </button>
        </div>
      ) : (
        <h2>Verifying your email...</h2>
      )}
    </div>
  );
};

export default VerifyEmail;
