// src/components/Login/Login.js
import React, { useState, useRef, useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "../../context/AuthContext";

const Login = () => {
  const navigate = useNavigate();
  const { isAuthenticated, loading, login } = useContext(AuthContext);
  const [loadingSpin, setLoadingSpin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [emailForResend, setEmailForResend] = useState("");
  const [verificationSent, setVerificationSent] = useState(false);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const passwordRef = useRef();
  passwordRef.current = watch("password", "");

  useEffect(() => {
    if (!loading && isAuthenticated) {
      navigate("/");
    }
  }, [loading, isAuthenticated, navigate]);

  const onSubmit = async (data) => {
    try {
      setLoadingSpin(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/login`,
        data
      );
      toast.success("Login successful");
      login(res.data.token);
      const lastVisitedPage = localStorage.getItem("lastVisitedPage") || "/";
      navigate(lastVisitedPage);
      setLoadingSpin(false);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      if (errorMessage === "Please verify your email") {
        setEmailForResend(data.email);
      }
      toast.error(errorMessage);
      setLoadingSpin(false);
    }
  };

  const handleResendVerification = async () => {
    try {
      setVerificationSent(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/resend-verification`,
        { email: emailForResend }
      );
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
    }
  };

  return (
    <div className="py-8 px-4 flex items-center justify-center">
      <ToastContainer />
      <form
        className="bg-gray-100 p-8 rounded-md w-full max-w-md"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h2 className="text-center">Welcome Back</h2>
        <p className="text-center">
          Don't have an account?{" "}
          <Link to="/register" className="underline">
            Signup
          </Link>
        </p>
        <div className="mb-4">
          <input
            type="email"
            {...register("email", {
              required: "Email is required",
              pattern: { value: /^\S+@\S+$/i, message: "Invalid email" },
            })}
            className="w-full py-2 px-3 border border-gray-300 rounded outline-none focus:outline-none"
            placeholder="Email"
          />
          {errors.email && (
            <p className="text-red-500 text-sm mt-1">{errors.email.message}</p>
          )}
        </div>
        <div className="mb-4">
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              {...register("password", {
                required: "Password is required",
              })}
              className="w-full py-2 px-3 border border-gray-300 rounded outline-none focus:outline-none"
              placeholder="Password"
            />
            {passwordRef.current && (
              <span
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              >
                {showPassword ? (
                  <i className="fa-regular fa-eye text-gray-400"></i>
                ) : (
                  <i className="fa-regular fa-eye-slash text-gray-400"></i>
                )}
              </span>
            )}
          </div>
          {errors.password && (
            <p className="text-red-500 text-sm mt-1">
              {errors.password.message}
            </p>
          )}
        </div>

        <button
          type="submit"
          className="mt-2 w-full bg-black text-white py-2.5 px-3 rounded"
          disabled={loadingSpin}
        >
          {loadingSpin ? (
            <i className="fa-solid fa-spinner animate-spin"></i>
          ) : (
            "Login"
          )}
        </button>

        <div className="text-center mt-4">
          <Link to="/forgot-password" className="underline">
            Forgot Password?
          </Link>
        </div>

        {emailForResend && (
          <div className="mt-6">
            {verificationSent ? (
              <p className="text-green-500 m-0">
                Verification email sent successfully!
              </p>
            ) : (
              <>
                <p className="text-red-500 m-0">
                  Didn't receive the verification email?
                </p>
                <button
                  type="button"
                  onClick={handleResendVerification}
                  className="text-black underline"
                >
                  Resend Verification Email
                </button>
              </>
            )}
          </div>
        )}
      </form>
    </div>
  );
};

export default Login;
