import React, { useEffect, useState } from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import image1 from '../../assets/images/1.jpg'
import { useNavigate } from 'react-router-dom';

const responsive = {
  desktop: { breakpoint: { max: 3000, min: 1024 }, items: 2 },
  tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
  mobile: { breakpoint: { max: 464, min: 0 }, items: 1.5 },
};

const CarouselComponent = ({
  items,
  title
}) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div
    className='mb-6'
    >
      <h3 className="text-center mb-5 font-playfair font-bold whitespace-nowrap">
        {title}
      </h3>
      <p className="text-start font-lato">
      Your story is powerful, but it can be so much more. Take it to the next level—turn it into something that others can connect with, learn from, and be inspired by. Ready to make an impact? Let’s bring your story to life.
      </p>
      <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={true}
        showDots={false}
        infinite={false}
        autoPlay={false}
        keyBoardControl={true}
        transitionDuration={500}
        containerClass="carousel-container"
        itemClass="carousel-item-padding-40-px"
        arrows={isDesktop} 
      >
        {items.map((item, index) => (
          <div key={index} className="text-start pr-4"
          
          >
            {
              item.video ? (
                <video
                  src={item.imgSrc}
                  className="w-[380px] h-[370] object-cover mb-1 cursor-pointer"
                  autoplay="autoplay" controls muted
                  
                  onClick={
                   () => navigate(`/turn-your-story-into-a-film/673572704ad06964d572ac16 `)
                  }
                />
              ) : (
                <img
              src={item.imgSrc}
              alt={item.title}
              className="w-[380px] h-[370] object-cover mb-1"
            />
              )
            }

            <span>{item.description}</span>
          </div>
        ))}
      </Carousel>
    </div>
  );
};
const TurnYourStorySection = () => {
  return (
    <section>
      
      <CarouselComponent title='TRANSFORM YOUR STORY' 
        items={[
    {
      imgSrc: 'https://melo-story.s3.eu-north-1.amazonaws.com/MeloStories/video.mp4',
      description: "Short Film",
      video: true
    },
    {
      imgSrc: image1,
      description: "Printed Gift",
    },
  ]}
      />
    </section>
  );
};

export default TurnYourStorySection;