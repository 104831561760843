import React, { useState, useEffect, useRef } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Chatbot from "./Chatbot";
import ResultScreen from "./ResultScreen";
import { useNavigate } from "react-router-dom";
import StoryCard from "../ui/Card/StoryCard";

const ChatbotPage = () => {
  const [step, setStep] = useState(1);
  const [finalMessg, setFinalMessg] = useState("");
  const [selectedBox, setSelectedBox] = useState("");
  const [isBoxLoading, setIsBoxLoading] = useState(false);
  const navigate = useNavigate();
  // Refs to each box for scrolling into view
  const boxRefs = useRef([]);
  const scrollContainerRef = useRef(null);
  
  const [boxes, setBoxes] = useState([
    {
      title: "BUSINESS STORY",
      cardTitle:"Business Story",
      description: "Why You Started Your Business",
      prompt:"BUSINESS_PROMPT"
    },
    {
      title: "MEMORIES",
      cardTitle:"Memories",
      description: "Cherished Moments In Your Life",
      prompt:'MEMORIES_PROMPT'
    },
    {
      title: "FOR SOMEONE",
      cardTitle:"For Someone",
      description: "Write a Meaningful Story For Someone Special",
      prompt:'FOR_SOMEONE_PROMPT'
    },
    {
      title: "MILESTONE",
      cardTitle:"Milestone",
      description: "Key Life Events",
      prompt:'MILESTONE_PROMPT'
    },

  ]); 
  const scrollToCenter = (index) => {
    const selectedBoxElement = boxRefs.current[index];
    const scrollContainer = scrollContainerRef.current;

    if (selectedBoxElement && scrollContainer) {
      const containerWidth = scrollContainer.clientWidth; // Width of the scroll container
      const boxWidth = selectedBoxElement.clientWidth; // Width of the selected box
      const boxOffset = selectedBoxElement.offsetLeft; // Left position of the selected box

      // Calculate the scroll position to center the box
      const scrollPos = boxOffset - containerWidth / 2 + boxWidth / 2 - 20;

      // Smooth scrolling to the calculated position
      scrollContainer.scrollTo({ left: scrollPos, behavior: "smooth" });
    }
  };

  const handleBoxClick = (box, index) => {
    const tabName = box.title.toLowerCase().replace(/\s+/g, "-");
      navigate({ search: `?tab=${tabName}` });
      setSelectedBox(box.title);
      scrollToCenter(index);
      // get the step from local storage
      const savedStep = localStorage.getItem(box.title+"step");
      if (savedStep) {
        setStep(parseInt(savedStep, 10));
      }
      else{
        setStep(1);
      }
      const savedFinalMessg = localStorage.getItem(box.title+"finalMessg");

      if (savedFinalMessg) {
        setFinalMessg(savedFinalMessg);
      }
      else{
        setFinalMessg("");
      }
  };


  return (
    <>
      <ToastContainer />
      {
        selectedBox ?(
          <>
          <div
        className={`w-full fixed left-0 right-0 bg-white px-6 flex items-center justify-center bottom-3 `}
        style={{ zIndex: 1001, overflow: "visible" }} // Ensure overflow is visible
      >
        <div
          ref={scrollContainerRef}
          className="overflow-x-auto whitespace-nowrap box-scrollbar scroll-smooth"
        >
          {boxes.map((box, index) => (
            <div
              key={index}
              ref={(el) => {
                boxRefs.current[index] = el;
              }}
              className={`rounded-xl cursor-pointer flex-shrink-0 inline-flex flex-col items-center mr-4 py-2 px-6 relative
               
                ${
                  box.title === selectedBox
                    ? "bg-mediumgray"
                    : ""
                }
                
                `}
              onClick={() => handleBoxClick(box, index)}
            >
              <div
                className={`relative  ${
                  isBoxLoading || box.title === selectedBox
                    ? "text-start min-w-[200px]"
                    : "text-start"
                }`}
              >
                {!isBoxLoading || box.title !== selectedBox ? (
                  <div className="text-xs m-0">
                    <div className="flex items-center">
                     
                      <h6
                      className="mb-0"
                      >{box.title}</h6>
                      {/* Optional: Add spinner on "Price and Process" tab */}
                      {/* {box.title === "Price and Process" && !isCleaned && (
                        <i className="fa-solid fa-spinner animate-spin ml-2 text-sm"></i>
                      )} */}
                    </div>
                    <span 
                    className=" text-darkergray"
                    >{box.description}</span>
                  </div>
                ) : (
                  <i className="fa-solid fa-spinner animate-spin"></i>
                )}
              </div>
            </div>
          ))}
        </div>
        
      </div>
      <div className="flex flex-col items-center">
        <div className="flex-1 flex items-center justify-center w-full">
          {step === 1 ? (
            <Chatbot setStep={setStep} setFinalMessg={setFinalMessg} 
              prompt={boxes.find((box) => box.title === selectedBox).prompt}
              stepKey={selectedBox+"step"}
              finalMessgKey={selectedBox+"finalMessg"}
            />
          ) : step === 2 ? (
            <ResultScreen finalMessg={finalMessg} setStep={setStep} 
              prompt={boxes.find((box) => box.title === selectedBox).prompt}
              stepKey={selectedBox+"step"}
              finalMessgKey={selectedBox+"finalMessg"}
            />
          ) : null}
        </div>
      </div>

          </>

        ):(
          <>
          <div
        className={`w-full fixed left-0 right-0 bg-white px-7 flex items-center justify-center bottom-6 `}
        style={{ zIndex: 1001, overflow: "visible" }} // Ensure overflow is visible
      >
        <div className="grid grid-cols-2 gap-x-2 gap-y-3">
      {boxes.map((story, index) => (
        <StoryCard
          onClick={() => handleBoxClick(story, index)}
          key={index}
          title={story.cardTitle}
          description={story.description}
        />
      ))}
    </div>
        
      </div>
      <div class="flex justify-center items-center align-middle pt-32 flex-col">
      <h4>HEY, IT’S SERENA. LET’S GET STARTED.</h4>
      <p
      className="text-center px-6 font-lato" 
      >In a few minutes receive your: story, content strategy, created content & values. Try it below.</p>
        
        </div>
        </>
      )
      }
      
    </>
  );
};

export default ChatbotPage;
