import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Checkout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [validatingCoupon, setValidatingCoupon] = useState(false);
  const [isValid, setIsValid] = useState(false);
  // useEffect(() => {
  //   if (!product) {
  //     navigate("/"); // Redirect if no product data is available
  //   }
  // }, [product, navigate]);
  const validateCoupon = async (couponCode) => {
    setValidatingCoupon(true);
    try {
      const data={
        code:couponCode
      }
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/coupon/validate`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.data.success) {
        setIsValid(true);
        setProduct((prevProduct) => ({
          ...prevProduct,
          price: prevProduct.price * (1 - response.data.data / 100),
        }));
        localStorage.setItem("checkoutProduct", JSON.stringify({
          ...product,
          price: product.price * (1 - response.data.data / 100),
        }));
      }
      return null;
    } catch (error) {
      console.error(error);
      toast.error(error.response?.data?.message);
    } finally {
      setValidatingCoupon(false);
    }
  }
  useEffect(() => {
    if (!product) {
      const storedProduct = localStorage.getItem("checkoutProduct");
      if (storedProduct) {
        setProduct(JSON.parse(storedProduct));
      }
    }
  }, [product, navigate]);

  const makePayment = async () => {
    try {
      setLoading(true);
      const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);

      const requestBody = { product };

      if (product.name === "Story Bundle") {
        requestBody.userMessage = `You are a skilled storyteller. You will be given chat history which contains user interaction with the chat. Now, based on that conversation, you will generate a story.
      Please format the response using only the following HTML tags: <h4> for headings, <p> for paragraphs, and <ul> or <ol> with <li> for lists and bullet points or numbering items. Ensure that the content is structured and formatted clearly using these tags. Make sure to include the specified headings and follow the given structure for the story.
    
            Here is the structure to follow:
    
            <h4>Short story name (maximum 10 words)</h4> 
            [[Minimum 500 words for the story]]
            <p>Paragraph1</p>
            <p>Paragraph2</p>
            <p>Paragraph3</p>

            <h4>Your values</h4>
            <ol>
              <li>Value 1</li>
              <li>Value 2</li>
              <li>Value 3</li>
            </ol>
    
            <h4>Your content strategy</h4>
            <ol>
              <li>Strategy 1</li>
              <li>Strategy 2</li>
              <li>Strategy 3</li>
              <!-- Provide at least 20 content strategy ideas -->
            </ol>
    
            <h4>Your digital media schedule</h4>
            <ul>
              <li>Monday: Schedule details</li>
              <li>Tuesday: Schedule details</li>
              <li>Wednesday: Schedule details</li>
              <li>Thursday: Schedule details</li>
              <li>Friday: Schedule details</li>
              <li>Saturday: Schedule details</li>
              <li>Sunday: Schedule details</li>
            </ul>
            
            Important Note: Generate a positive, engaging, and appropriate story based on the user conversation with the chatgpt system. Avoid any content that could be considered inappropriate, offensive, or harmful. Do not include any introductory phrases, explanations, or ask for any further input or clarification. Start directly with the story content as per the provided structure and chat history. Do not include any concluding sentences or summaries such as "This concludes the story" or "If you need any adjustments or further assistance, feel free to let me know." End the story naturally without any closing remarks.
            `;
        requestBody.chatHistory = JSON.parse(
          localStorage.getItem(product.prompt)
        );
        requestBody.introStoryLines = localStorage.getItem(`introStoryLines_${product.prompt}`);
      }

      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/checkout`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        setLoading(false);
        stripe.redirectToCheckout({ sessionId: response.data.message });
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
      setLoading(false);
    }
  };
  const addStory = async () => {
    try {
      setLoading(true);
      const requestBody = { product };
      if (product.name === "Story Bundle") {
        requestBody.userMessage = `You are a skilled storyteller. You will be given chat history which contains user interaction with the chat. Now, based on that conversation, you will generate a story.
      Please format the response using only the following HTML tags: <h4> for headings, <p> for paragraphs, and <ul> or <ol> with <li> for lists and bullet points or numbering items. Ensure that the content is structured and formatted clearly using these tags. Make sure to include the specified headings and follow the given structure for the story.
    
            Here is the structure to follow:
    
            <h4>Short story name (maximum 10 words)</h4> 
            [[Minimum 500 words for the story]]
            <p>Paragraph1</p>
            <p>Paragraph2</p>
            <p>Paragraph3</p>

            <h4>Your values</h4>
            <ol>
              <li>Value 1</li>
              <li>Value 2</li>
              <li>Value 3</li>
            </ol>
    
            <h4>Your content strategy</h4>
            <ol>
              <li>Strategy 1</li>
              <li>Strategy 2</li>
              <li>Strategy 3</li>
              <!-- Provide at least 20 content strategy ideas -->
            </ol>
    
            <h4>Your digital media schedule</h4>
            <ul>
              <li>Monday: Schedule details</li>
              <li>Tuesday: Schedule details</li>
              <li>Wednesday: Schedule details</li>
              <li>Thursday: Schedule details</li>
              <li>Friday: Schedule details</li>
              <li>Saturday: Schedule details</li>
              <li>Sunday: Schedule details</li>
            </ul>
            
            Important Note: Generate a positive, engaging, and appropriate story based on the user conversation with the chatgpt system. Avoid any content that could be considered inappropriate, offensive, or harmful. Do not include any introductory phrases, explanations, or ask for any further input or clarification. Start directly with the story content as per the provided structure and chat history. Do not include any concluding sentences or summaries such as "This concludes the story" or "If you need any adjustments or further assistance, feel free to let me know." End the story naturally without any closing remarks.
            `;
        requestBody.chatHistory = JSON.parse(
          localStorage.getItem(product.prompt)
        );
        requestBody.introStoryLines = localStorage.getItem(`introStoryLines_${product.prompt}`);
      }
      
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/story/add-story`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.success) {
        toast.success(response.data.message);
        setLoading(false);
        navigate("/story");
      }
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
      setLoading(false);
    }
  }
  if (!product) {
    return (
      <div className="h-screen flex items-center justify-center">
        <p>No item in the checkout</p>
      </div>
    );
  }

  return (
    <div className="max-w-md mx-auto p-4">
      <ToastContainer />
      <h3 className="text-center font-semibold leading-6 text-gray-900">
        Order Summary
      </h3>

      <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
        <div className="space-y-4 px-4 pt-4">
          <p className="font-medium text-gray-700 hover:text-gray-800">
            {product?.name}
          </p>

          <p className="mt-1 text-sm">Includes</p>
          <div dangerouslySetInnerHTML={{ __html: product?.description }} />
        </div>
        
      <div className="flex gap-2 p-4 border-t border-gray-200">
        <input
          type="text"
          placeholder="Enter coupon code"
          className="flex-1 px-4 py-2 border border-gray-300 rounded-md focus:outline-none"
          value={couponCode}
          onChange={(e) => setCouponCode(e.target.value)}
          disabled={isValid || loading || validatingCoupon}
          aria-disabled={isValid || loading || validatingCoupon}
        />
        <button 
                    className="rounded-md transition-all bg-black px-3 text-sm font-semibold text-white shadow-sm hover:opacity-75"
                    onClick={
                      ()=>{
                        validateCoupon(couponCode)
                      }
                    }
                    disabled={validatingCoupon || !couponCode || isValid}
                    aria-disabled={validatingCoupon || !couponCode || isValid}
                    >
          {
            validatingCoupon ? ( <i className="fa-solid fa-spinner animate-spin"></i> ) : "Apply"
          }
        </button>
      </div>
   
        <dl className="space-y-6 border-t border-gray-200">
          <div className="flex items-center justify-between p-4">
            <dt className="text-base font-medium">Total</dt>
            <dd className="text-base font-medium text-gray-900">
              ${product?.price.toFixed(2)}
            </dd>
          </div>
        </dl>

        <div className="border-t border-gray-200 sm:flex sm:flex-row-reverse px-4 py-6">
          <button
            className="rounded-md transition-all w-full bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:opacity-75 sm:ml-3 sm:w-1/2"
            onClick={()=>{
              product?.price.toFixed(2) == 0.00 ? addStory() : makePayment()
            }}
            disabled={loading || validatingCoupon}

          >
            {loading ? (
              <i className="fa-solid fa-spinner animate-spin"></i>
            ) : (
              product?.price.toFixed(2) == 0.00 ? "Add to Stories" :
              "Proceed to Pay"
            )}
          </button>
          <button
            className="rounded-md transition-all mt-3 w-full bg-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-1/2 "
            onClick={() => navigate(-1)}
            disabled={loading || validatingCoupon}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
