import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import image1 from "../../assets/images/4.jpg";
import image2 from "../../assets/images/5.jpg";
import image3 from "../../assets/images/6.jpg";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2.5
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1.5
  }
}
const CarouselComponent = ({ title = "CREATED CONTENT " }) => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);
  const items = [
    
    {
      imgSrc: image1,
      description: "From a kid in Campbelltown to the runway. Still, when I see photos of brides in my dresses, I get goosebumps. To be a big part of someone’s day like that—it’s amazing.",
    },
    {
      imgSrc: image2,
      description: "Seeing this in the window feels surreal. From sewing in my bedroom to here... it’s been quite a journey.",
    },
    {
      imgSrc: image3,
      description: "It all started with scraps of fabric and a big dream. Every gown reminds me of how far it’s come.",
    },
    
  ];
  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div>
      <h3 className="text-center mb-5 font-playfair font-bold">{title}</h3>
      <p className=" text-start font-lato">
      Created content from your story in minutes. Specifically tailored content for your social media content. See caption and image below:{" "}
      </p>

      <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={true}
        showDots={false}
        infinite={true}
        autoPlay={false}
        keyBoardControl={true}
        transitionDuration={500}
        containerClass="carousel-container"
        itemClass="carousel-item-padding-0-px"
        arrows={isDesktop}
      >
        {items.map((item, index) => (
          <div key={index} className="text-start pr-4">
            <img
              src={item.imgSrc}
              alt={item.title}
              className="w-[380px] h-[370] object-cover mb-1"
            />
            <p className="leading-relaxed text-start font-lato">
              {item.description}
            </p>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CarouselComponent;
