import { BiEdit } from "react-icons/bi";
import { CiEdit } from "react-icons/ci";
import { LuImagePlus, LuShare2 } from "react-icons/lu";

export default function CaptionCard({
    caption,
    handleImageUpload,
    handleCaptionChange,
    editingIndex,
    setEditingIndex,
    updateCaption,
}) {

  return (
    <>
        <div className="w-full aspect-square mb-2 bg-lightgray overflow-hidden">
              {caption?.url ? (
                <img
                  src={caption.url}
                  alt={''}
                  
                  className="w-full h-full object-cover"
                />
              ) : (
                <label
                  htmlFor={`image-upload-${caption._id}`}
                  className="w-full h-full flex items-center justify-center cursor-pointer"
                >
                  <LuImagePlus className="w-8 h-8 text-darkergray" />
                  <input
                    type="file"
                    id={`image-upload-${caption._id}`}
                    accept="image/*"
                    onChange={(e) => handleImageUpload(caption._id, e)}
                    className="hidden"
                  />
                </label>
              )}
              {
                caption?.url && (<div className="absolute top-2 right-2 flex space-x-2">
        {/* <button
          variant="ghost"
          size="icon"
          className="bg-darkgray p-1 rounded-sm text-black  cursor-pointer"
        >
          <LuShare2 title="share" className="w-3 h-3 md:w-4 md:h-4" />
        </button> */}
        
        <label
                  htmlFor={`image-reupload-${caption._id}`}
                  className="bg-darkgray p-1 rounded-sm text-black  cursor-pointer"
                >
                  <LuImagePlus className="w-3 h-3 md:w-4 md:h-4" />
                  <input
                    type="file"
                    id={`image-reupload-${caption._id}`}
                    accept="image/*"
                    onChange={(e) => handleImageUpload(caption._id, e)}
                    className="hidden"
                  />
                </label>
      </div>)
              }
              
            </div>
            {
              editingIndex === caption._id ? (
                <textarea
                  value={caption.text}
                  onChange={(e) => handleCaptionChange(caption._id, e)}
                  className="w-full h-20 p-2 border border-darkergray resize-none focus:outline-none focus:ring-0 "
                  onBlur={(e) => {
                    updateCaption(caption._id, e);
                    setEditingIndex(null)}}
                    autoFocus
                />
              ) : (

            <p className="text-sm text-center text-darkergray">
              {caption.text}{' '}<button
              className="bg-darkgray p-1 rounded-sm text-black  cursor-pointer"
              onClick={() => setEditingIndex(caption._id)}
            >
            <CiEdit title="edit" className="w-4 h-4 text-black" />
            </button>
            </p>
            

              )
            }
            
    </>
  )
}