import React from 'react'


export default function OverlayImage({ imageUrl, title, description, alt }) {
  return (
    <div className="relative">
      <img
        src={imageUrl}
        alt={alt}
        width={600}
        height={400}
        className="w-full h-auto"
      />
      <div className="absolute inset-0 flex flex-col items-center justify-center p-4 bg-black bg-opacity-0 text-white text-center">
        <h1 className="font-bolder mb-0 uppercase font-playfair">{title}</h1>
        <h1
        className='uppercase font-playfair '
        >{description}</h1>
      </div>
    </div>
  )
}