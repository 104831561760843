// src/components/ResetPassword.js
import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ResetPassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const { token } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm();

  const [loading, setLoading] = useState(false);

  const passwordRef = useRef();
  passwordRef.current = watch("password", "");

  const confirmPasswordRef = useRef();
  confirmPasswordRef.current = watch("confirmPassword", "");

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      const res = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/reset-password/${token}`,
        data
      );
      toast.success(res.data.message);
      setLoading(false);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
      setLoading(false);
    }
  };

  return (
    <div className="py-8 px-4 flex items-center justify-center">
      <ToastContainer />
      <form
        className="bg-gray-100 p-8 rounded-md w-full max-w-md"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h2 className="text-center">Reset Password</h2>
        <div className="mb-4">
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              {...register("password", {
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters",
                },
              })}
              className="w-full py-2 px-3 border border-gray-300 rounded outline-none focus:outline-none"
              placeholder="New Password"
            />
            {passwordRef.current && (
              <span
                onClick={togglePasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              >
                {showPassword ? (
                  <i className="fa-regular fa-eye text-gray-400"></i>
                ) : (
                  <i className="fa-regular fa-eye-slash text-gray-400"></i>
                )}
              </span>
            )}
          </div>
          {errors.password && (
            <p className="text-red-500 text-sm mt-1">
              {errors.password.message}
            </p>
          )}
        </div>

        <div className="mb-4">
          <div className="relative">
            <input
              type={showConfirmPassword ? "text" : "password"}
              {...register("confirmPassword", {
                required: "Please confirm your password",
                validate: (value) =>
                  value === getValues("password") || "Passwords do not match",
              })}
              className="w-full py-2 px-3 border border-gray-300 rounded outline-none focus:outline-none"
              placeholder="Confirm Password"
            />
            {confirmPasswordRef.current && (
              <span
                onClick={toggleConfirmPasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              >
                {showConfirmPassword ? (
                  <i className="fa-regular fa-eye text-gray-400"></i>
                ) : (
                  <i className="fa-regular fa-eye-slash text-gray-400"></i>
                )}
              </span>
            )}
          </div>
          {errors.confirmPassword && (
            <p className="text-red-500 text-sm mt-1">
              {errors.confirmPassword.message}
            </p>
          )}
        </div>
        <button
          type="submit"
          className="mt-2 w-full bg-black text-white py-2.5 px-3 rounded"
          disabled={loading}
        >
          {loading ? (
            <i className="fa-solid fa-spinner animate-spin"></i>
          ) : (
            "Reset Password"
          )}
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
