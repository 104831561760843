import React, { useState, useRef } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
  } = useForm();

  const [loading, setLoading] = useState(false);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const toggleCurrentPasswordVisibility = () =>
    setShowCurrentPassword(!showCurrentPassword);

  const [showNewPassword, setShowNewPassword] = useState(false);
  const toggleNewPasswordVisibility = () =>
    setShowNewPassword(!showNewPassword);

  const currentPasswordRef = useRef();
  currentPasswordRef.current = watch("currentPassword", "");

  const newPasswordRef = useRef();
  newPasswordRef.current = watch("newPassword", "");

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      await axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/auth/change-password`,
        data,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      toast.success("Password changed successfully");
      setLoading(false);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        error.message ||
        "An unexpected error occurred";
      toast.error(errorMessage);
      setLoading(false);
    }
  };

  return (
    <div className="py-8 px-4 flex items-center justify-center">
      <ToastContainer />
      <form
        className="bg-gray-100 p-8 rounded-md w-full max-w-md"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h2 className="text-center">Change Password</h2>
        <div className="mb-4">
          <div className="relative">
            <input
              type={showCurrentPassword ? "text" : "password"}
              {...register("currentPassword", {
                required: "Current password is required",
              })}
              className="w-full py-2 px-3 border border-gray-300 rounded outline-none focus:outline-none"
              placeholder="Current Password"
            />
            {currentPasswordRef.current && (
              <span
                onClick={toggleCurrentPasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              >
                {showCurrentPassword ? (
                  <i className="fa-regular fa-eye text-gray-400"></i>
                ) : (
                  <i className="fa-regular fa-eye-slash text-gray-400"></i>
                )}
              </span>
            )}
          </div>
          {errors.currentPassword && (
            <p className="text-red-500 text-sm mt-1">
              {errors.currentPassword.message}
            </p>
          )}
        </div>

        <div className="mb-4">
          <div className="relative">
            <input
              type={showNewPassword ? "text" : "password"}
              {...register("newPassword", {
                required: "New password is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters",
                },
              })}
              className="w-full py-2 px-3 border border-gray-300 rounded outline-none focus:outline-none"
              placeholder="New Password"
            />
            {newPasswordRef.current && (
              <span
                onClick={toggleNewPasswordVisibility}
                className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer"
              >
                {showNewPassword ? (
                  <i className="fa-regular fa-eye text-gray-400"></i>
                ) : (
                  <i className="fa-regular fa-eye-slash text-gray-400"></i>
                )}
              </span>
            )}
          </div>
          {errors.newPassword && (
            <p className="text-red-500 text-sm mt-1">
              {errors.newPassword.message}
            </p>
          )}
        </div>

        <button
          type="submit"
          className="mt-2 w-full bg-black text-white py-2.5 px-3 rounded"
          disabled={loading}
        >
          {loading ? (
            <i className="fa-solid fa-spinner animate-spin"></i>
          ) : (
            "Change Password"
          )}
        </button>
      </form>
    </div>
  );
};

export default ChangePassword;
