import React from "react";
import { useNavigate } from "react-router-dom";

const LoginModal = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="relative z-10" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500/75 transition-opacity"></div>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <div className="relative overflow-hidden rounded-lg bg-white text-center shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="flex flex-col items-center justify-center">
                  <div className="mx-auto flex h-12 w-12 shrink-0 items-center justify-center rounded-full bg-violet-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                      className="h-6 w-6 text-black"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z"
                      ></path>
                    </svg>
                  </div>
                  <div className="mt-6 sm:text-left">
                    <h3 className="text-center text-xl font-semibold leading-6 text-gray-900">
                      Please Log In
                    </h3>
                    <div className="mt-3 text-center text-sm text-gray-500">
                      <p>To access this feature, you'll need to log in. </p>
                      <p> Don't have an account yet? Sign up now!</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-3 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                <button
                  className="rounded-md transition-all w-full bg-black px-3 py-2 text-sm font-semibold text-white shadow-sm hover:opacity-75 sm:ml-3 sm:w-1/2"
                  onClick={() => navigate("/login")}
                >
                  Sign in
                </button>
                <button
                  className="rounded-md transition-all mt-3 w-full bg-white px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-1/2 "
                  onClick={() => navigate("/register")}
                >
                  Sign up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
