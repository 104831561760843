import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useCache } from "../../CacheContext";

const Film = () => {
  const [openStoryId, setOpenStoryId] = useState(null);
  const [loading, setLoading] = useState(true);

  const { setCacheData, getCacheData } = useCache();
  const [films, setFilms] = useState(() => getCacheData("films") || []);

  useEffect(() => {
    if (!films.length) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/api/story?isFilm=true`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((response) => {
          console.log("films api call made");
          setFilms(response.data.data);
          setCacheData("films", response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.message ||
            error.message ||
            "An unexpected error occurred while fetching the films.";
          toast.error(errorMessage);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [films, setCacheData]);

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <i className="fa-solid fa-spinner animate-spin text-2xl"></i>
      </div>
    );
  }

  if (films.length === 0) {
    return (
      <div className="h-screen flex items-center justify-center">
        <p>No films found</p>
      </div>
    );
  }

  const toggleStoryContent = (id) => {
    setOpenStoryId(openStoryId === id ? null : id);
  };

  return (
    <div className="max-w-4xl mx-auto p-4">
      <ToastContainer />
      <h3 className="text-center font-semibold leading-6 text-gray-900">
        Your Films
      </h3>
      <div className="pt-4 w-full">
        <div className="flex flex-col items-center justify-center w-full">
          {films.map((story) => (
            <div
              key={story._id}
              className="border border-gray-200 shadow-sm p-4 rounded mb-6 w-full"
            >
              <div className="flex items-center w-full">
                <div className="flex flex-row justify-between w-full">
                  <div>
                    <p className="font-semibold text-gray-900">Film</p>
                    <p className="text-sm leading-normal">
                      Purchased:{" "}
                      <span className="text-gray-500">
                        {moment().diff(moment(story.updatedAt), "hours") < 24
                          ? moment(story.updatedAt).fromNow()
                          : moment(story.updatedAt).format("MMMM Do YYYY")}
                      </span>
                    </p>
                  </div>
                  <div className="flex items-center justify-center">
                    <button onClick={() => toggleStoryContent(story._id)}>
                      {openStoryId === story._id ? (
                        <i class="fa-solid fa-circle-chevron-up"></i>
                      ) : (
                        <i class="fa-solid fa-circle-chevron-down"></i>
                      )}
                    </button>
                  </div>
                </div>
              </div>
              {openStoryId === story._id && (
                <div className="mt-4 p-4 border-t border-gray-200">
                  <h3 className="text-center font-semibold leading-6 text-gray-900">
                    {story.name}
                  </h3>
                  <div
                    dangerouslySetInnerHTML={{ __html: story.description }}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Film;
