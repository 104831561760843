import React, { useState } from 'react';
import { BsChevronUp, BsChevronDown } from "react-icons/bs";

const YourValues = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <section>
      
      <h3 className="text-center mb-5 font-playfair font-bold">
      YOUR VALUES
        </h3>
        <p className="text-start font-lato">
        Your brand is based on your values. Your values should be injected into every part of your brand.
      </p>
        <div
        className='flex items-center justify-center cursor-pointer'
        >
        
        <button
          onClick={() => setIsExpanded(!isExpanded)}
          className="text-xs flex items-center gap-2"
        >
          <span>{!isExpanded ? "VIEW EXAMPLE" : "HIDE EXAMPLE"}</span>
          {isExpanded ? (
            <BsChevronUp className="ml-1 w-4 h-4" />
          ) : (
            <BsChevronDown className="ml-1 w-4 h-4" />
          )}
        </button>
        </div>
       
     
      <div className="space-y-6">

          {isExpanded && (
            <div className="animate-fadeIn space-y-6">
              <div>
                <h4>Resilience:</h4>
                
                  <p>Overcoming challenges from his tough upbringing, he built a successful career through hard work and determination, staying true to his passion even when the path wasn’t easy.
</p>
              
              </div>

              <div>
                <h4>Creativity:</h4>
             
                  <p>From a young age, he found solace and expression in creativity, using whatever materials he had to pursue his love for design. This passion for creativity drives his work and his dedication to making each piece special.
</p>
               
              </div>

              <div>
                <h4>Gratitude:</h4>
                
                  <p>Steven shows deep appreciation for the people who supported him, especially his mother, and for the journey he’s been on. He values the opportunity to do what he loves and be a part of special moments in his clients' lives.</p>
                
              </div>

            </div>
          )}
        </div>
    </section>
  );
};

export default YourValues;