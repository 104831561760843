import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProductCard = ({ _id, name, imageUrl, basePrice, link }) => {
  const navigate = useNavigate();
  return (
    <div
      className="flex flex-col overflow-hidden duration-300 ease-in-out items-center space-y-2 cursor-pointer group"
      onClick={() => navigate(`/product/${_id}`)}
    >
      <img
        src={imageUrl}
        className="rounded-lg transform transition-transform duration-300 group-hover:scale-95"
      ></img>
      <p className="group-hover:underline">{name}</p>
      <a href={link} className="text-sm">
        From ${basePrice.toFixed(2)}
      </a>
    </div>
  );
};

const ContinueStory = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch stories from the backend
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/api/category/continue-your-story`
      )
      .then((response) => {
        setProducts(response.data.data); // Adjust the response path accordingly
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Error fetching products:", error.message);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <i className="fa-solid fa-spinner animate-spin text-2xl"></i>
      </div>
    );
  }

  if (!products || products.length === 0) {
    return (
      <div className="h-screen flex items-center justify-center">
        <p>No products found</p>
      </div>
    );
  }

  return (
    <div className="container mx-auto flex items-center justify-center flex-col space-y-2 relative px-4 py-8">
      <ToastContainer />
      <div className="grid grid-cols-2 md:grid-cols-3 gap-2 lg:gap-4 ">
        {products.length > 0 &&
          products.map((product, index) => (
            <div key={index} className="px-2 py-4 lg:p-4">
              <ProductCard {...product} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default ContinueStory;
