import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { shareOnMobile } from "react-mobile-share";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import { useCache } from "../../CacheContext";
import CaptionCard from "../ui/Card/CaptionCard";
import CoverImage from "../CoverImage";
import { BiEdit } from "react-icons/bi";
import Modal from "../ui/Modal";
import EditStory from "./EditStory";

const SingleStory = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [pageLoading, setPageLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [captions, 
    setCaptions,] = useState([])

  const handleImageUpload = (imageId, event) => {
    const file = event.target.files?.[0]
    if (file) {
      // /image-upload/:storyId
      const formData = new FormData()
      formData.append('image', file)
      formData.append('_id', imageId)
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/story/image-upload/${id}`,formData,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      const imageUrl = URL.createObjectURL(file)
      setCaptions(captions.map(img => 
        img._id === imageId ? { ...img, url: imageUrl } : img
      ))
    }
  }
  const handleCaptionChange = (captionId, event) => {
    const newCaptions = captions.map((caption) => {
      if (caption._id === captionId) {
        return {
          ...caption,
          text: event.target.value,
        };
      }
      return caption;
    });
    setCaptions(newCaptions);
    
  }
  const updateCaption = async (captionId, event) => {
    try {
      axios.put(
        `${process.env.REACT_APP_BACKEND_URL}/api/story/${id}/${captionId}`,
        {
          text: event.target.value,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while updating the caption");
    }
  }
  const { getCacheData, setCacheData } = useCache();
  const [story, setStory] = useState(() => getCacheData(`story_${id}`) || null);
  const [storyPart1, setStoryPart1] = useState(null);
  const [storyPart2, setStoryPart2] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      setPageLoading(true);
      try {
        console.log("api call made");
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/story/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setStory(response.data.data);
        setCaptions(response.data.data.captions)
        setCacheData(`story_${id}`, response.data.data);
        setPageLoading(false);
        if(response?.data?.data?.description?.split("<h4>Your values</h4>")[1]){
          setStoryPart1(response?.data?.data?.description?.split("<h4>Your values</h4>")[0]);
          setStoryPart2(response?.data?.data?.description?.split("<h4>Your values</h4>")[1]);
        }else{
          setStoryPart1(response?.data?.data?.description?.split("<h4>Your Values</h4>")[0]);
          setStoryPart2(response?.data?.data?.description?.split("<h4>Your Values</h4>")[1]);
        }
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred while fetching the story.";
        toast.error(errorMessage);
        setPageLoading(false);
      }
    };
    if (!story) {
      fetchData();
    } else {
      if(story?.description?.split("<h4>Your values</h4>")[1]){
        setStoryPart1(story?.description?.split("<h4>Your values</h4>")[0]);
      setStoryPart2(story?.description?.split("<h4>Your values</h4>")[1]);
      }
      else{
        setStoryPart1(story?.description?.split("<h4>Your Values</h4>")[0]);
      setStoryPart2(story?.description?.split("<h4>Your Values</h4>")[1]);
      }
      setCaptions(story.captions)
      setPageLoading(false);
    }
  }, [id, story, setCacheData]);

  if (pageLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <i className="fa-solid fa-spinner animate-spin text-2xl"></i>
      </div>
    );
  }

  if (!story) {
    return (
      <div className="h-screen flex items-center justify-center">
        <p>No story found</p>
      </div>
    );
  }

  const turnStory = (id) => {
    navigate(`/turn-your-story-into-a-film/${id}`);
  };

  const copyToClipboard = (htmlContent) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    // Function to traverse and convert HTML to formatted text
    const traverseAndFormat = (node, prefix = "") => {
      let result = "";

      const traverse = (node, listPrefix = "") => {
        if (node.nodeType === Node.TEXT_NODE) {
          result += node.nodeValue;
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          if (node.tagName === "OL") {
            let count = 1;
            node.childNodes.forEach((child) => {
              if (child.tagName === "LI") {
                result += `${count}. `;
                traverse(child, `${count}. `);
                result += "\n"; // Add newline after each list item
                count++;
              } else {
                traverse(child);
              }
            });
          } else if (node.tagName === "UL") {
            node.childNodes.forEach((child) => {
              if (child.tagName === "LI") {
                result += "• ";
                traverse(child, "• ");
                result += "\n"; // Add newline after each list item
              } else {
                traverse(child);
              }
            });
          } else {
            node.childNodes.forEach(traverse);
          }

          if (node.tagName === "P") {
            result += "\n";
          }
        }
      };

      traverse(node);

      // Remove multiple line breaks
      result = result.replace(/\n\s*\n/g, "\n\n");

      return result;
    };

    const textToCopy = traverseAndFormat(tempDiv).trim();

    navigator.clipboard.writeText(textToCopy).then(() => {
      setShowTooltip(true);
      setTimeout(() => {
        setShowTooltip(false);
      }, 2000);
    });
  };

  const generateAndDownloadPDF = async () => {
    try {
      setLoading(true);
      // const htmlContent =
      //   `<h4 style="text-align:center; font-size:22px;">${story.name}</h4>` +
      //   story.description;
      let htmlContent=""
      const data={}
      if(storyPart1 && storyPart2){
        htmlContent =
        `<h4 style="text-align:center; font-size:22px;">${story.name}</h4>`;
        data['storyPart1']=storyPart1
        data['storyPart2']='<h4>Your Values</h4>' + storyPart2
      
      }else{
        htmlContent =
        `<h4 style="text-align:center; font-size:22px;">${story.name}</h4>` +
        story.description;
      }
      data['htmlContent']=htmlContent
      data['storyId']=id

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/story/generate-pdf`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Error saving pdf file");
      }

      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = "story.pdf";
      document.body.appendChild(link);
      link.click();
      link.remove();
      setLoading(false);
    } catch (e) {
      toast.error("Error: " + e.message);
      setLoading(false);
    }
  };
  const htmlToPlainText = (htmlContent) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlContent;

    // Function to traverse and convert HTML to formatted text
    const traverseAndFormat = (node, prefix = "") => {
      let result = "";

      const traverse = (node, listPrefix = "") => {
        if (node.nodeType === Node.TEXT_NODE) {
          result += node.nodeValue;
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          if (node.tagName === "OL") {
            let count = 1;
            node.childNodes.forEach((child) => {
              if (child.tagName === "LI") {
                result += `${count}. `;
                traverse(child, `${count}. `);
                result += "\n"; // Add newline after each list item
                count++;
              } else {
                traverse(child);
              }
            });
          } else if (node.tagName === "UL") {
            node.childNodes.forEach((child) => {
              if (child.tagName === "LI") {
                result += "• ";
                traverse(child, "• ");
                result += "\n"; // Add newline after each list item
              } else {
                traverse(child);
              }
            });
          } else {
            node.childNodes.forEach(traverse);
          }

          if (node.tagName === "P") {
            result += "\n";
          }
        }
      };

      traverse(node);

      // Remove multiple line breaks
      result = result.replace(/\n\s*\n/g, "\n\n");

      return result;
    };

    const textToCopy = traverseAndFormat(tempDiv).trim();
    return textToCopy;
  };

  return (
    <>
    <Modal
      isOpen={isEditing}
      onClose={() => {
        setIsEditing(false);
      }}
      title="Edit Story"
      className="max-w-4xl"
    >
    <EditStory markdown={story?.description}
    setIsEditing={setIsEditing}
    setStory={setStory}
    story={story}
    storyId={id}
    
    />
    </Modal>
      <div className="max-w-4xl mx-auto flex items-center justify-center flex-col space-y-2 relative px-4 py-8">
      <ToastContainer />
      <div>
        <h3 className="text-center font-semibold text-gray-900 leading-normal">
          {story.name}
        </h3>
        <BiEdit className="absolute top-4 right-4 cursor-pointer sm:w-8 sm:h-8 w-6 h-6" 
        onClick={() => {
          setIsEditing(true);
        }
        }/>
        
        {
          storyPart1 && (
            <div
          dangerouslySetInnerHTML={{ __html: storyPart1 }}
          className="text-content pb-4"
        />
          )
        }
        <CoverImage
          imageFile={story.image}
          storyId={story._id}
        />
        {captions?.length > 0 && (<><h4>Your Content</h4></>)}
        
        <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
        {captions.map((caption) => (
          <div
            key={caption._id}
            className="relative flex flex-col items-center"
          >
            <CaptionCard caption={caption} handleImageUpload={
              handleImageUpload
            } 
            editingIndex={editingIndex}
            setEditingIndex={setEditingIndex}
            handleCaptionChange={handleCaptionChange}
            updateCaption={updateCaption}
            />
          </div>
        ))}
      </div>
      {
        storyPart2 && (<div
          dangerouslySetInnerHTML={{ __html: '<h4>Your Values</h4>' + storyPart2 }}
          className="text-content pb-16"
        />)
      }
    
      </div>

      <div className="pb-24 flex flex-col items-center space-y-2">
        <h3 className="text-center font-semibold leading-6 text-gray-900">
          Turn your Story into a film
        </h3>

        <video
          autoplay="autoplay" controls muted
          playsInline
          preload="auto"
          className="max-h-[600px] mb-4"
        >
          <source
            src="https://melo-story.s3.eu-north-1.amazonaws.com/Dodie+Jay+Hair/Dodie+-+Draft+4+-+Reel+Format+(1)+(1).mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>

        <img
          src="https://melo-story.s3.eu-north-1.amazonaws.com/film2.png"
          className="max-h-[420px]"
        ></img>
        <img
          src="https://melo-story.s3.eu-north-1.amazonaws.com/film3.png"
          className="max-h-[320px]"
        ></img>
        <br></br>
        <br></br>
        {story.isFilm ? (
          <button
            className="rounded-md transition-all w-full bg-gray-300 px-3 py-2 text-sm font-semibold text-gray-700 shadow-sm"
            disabled
          >
            Already a film
          </button>
        ) : (
          <button
            onClick={() => {
              turnStory(story._id);
            }}
            className="rounded-md transition-all w-full bg-black px-3 py-2 font-semibold text-white shadow-sm hover:opacity-75 text-sm"
          >
            Buy your film
          </button>
        )}
      </div>

      <div className="max-w-4xl mx-auto fixed pt-4 pb-8 bottom-0 left-0 right-0 w-full bg-white border-t border-gray-200">
        <div className="flex items-center justify-center gap-4">
          <i
            className="relative cursor-pointer rounded-md transition-all bg-white px-4 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 fas fa-copy"
            onClick={() =>
              copyToClipboard(story.name + "\n\n" + story.description)
            }
            title="Copy to Clipboard!"
          >
            {showTooltip && (
              <div className="absolute -top-8 right-0 text-xs rounded bg-black text-white py-1 px-2">
                Copied!
              </div>
            )}
          </i>
          <button
            onClick={() => generateAndDownloadPDF()}
            className="rounded-md transition-all bg-white px-4 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            title="Download PDF"
            disabled={loading}
          >
            {loading ? (
              <i className="fa-solid fa-spinner animate-spin"></i>
            ) : (
              <i className="fas fa-file-pdf"></i>
            )}
          </button>
          <button
            className="rounded-md transition-all bg-black px-3 py-2 font-semibold text-white shadow-sm hover:opacity-75 text-sm"
            onClick={() =>
              shareOnMobile({
                title: htmlToPlainText(story.name + "\n\n" + story.description),
              })
            }
          >
            Share
          </button>
        </div>
      </div>
    </div>
    </>
  );
};

export default SingleStory;
