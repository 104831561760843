import axios from 'axios'
import { useEffect, useState } from 'react'
import { BiShare, BiUpload } from 'react-icons/bi'

export default function CoverImage({imageFile,storyId}) {

  const [image, setImage] = useState(null)

  useEffect(() => {
    if (imageFile && !image) {
     
        setImage(imageFile)
    }
  }, [imageFile])

  const handleImageUpload = (event) => {
    const file = event.target.files?.[0]
    if (file) {
    const formData = new FormData()
      formData.append('image', file)
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/story/image-upload/${storyId}`,formData,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      const reader = new FileReader()
      reader.onloadend = () => {
        setImage(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <div className="pb-8">
      <h4>Image Upload</h4>
      {!image ? (
        <div className="border-2 border-dashed border-gray-300 rounded-lg p-12 text-center">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="hidden"
            id="image-upload"
          />
          <label
            htmlFor="image-upload"
            className="cursor-pointer inline-flex items-center justify-center border border-transparent rounded-md transition-all bg-black px-3 py-2 font-semibold text-white shadow-sm hover:opacity-75 text-sm"
          >
            <BiUpload className="w-5 h-5 mr-2" />
            Upload Image
          </label>
        </div>
      ) : (
        <div className="space-y-4">
          <img src={image} alt="Uploaded" className="w-full object-cover" />
          <div className="flex justify-center space-x-4">
            <button 
            className="cursor-pointer inline-flex items-center justify-center border border-transparent rounded-md transition-all bg-black px-3 py-2 font-semibold text-white shadow-sm hover:opacity-75 text-sm"
            
            >
              <BiShare className="w-5 h-5 mr-2" />
              Share
            </button>
            <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="hidden"
            id="replace-image-upload"
          />
            <label
            htmlFor="replace-image-upload"
            className="cursor-pointer inline-flex items-center justify-center border border-transparent rounded-md transition-all bg-black px-3 py-2 font-semibold text-white shadow-sm hover:opacity-75 text-sm"
          >
            <BiUpload className="w-5 h-5 mr-2" />
            Replace
          </label>
          </div>
        </div>
      )}
    </div>
  )
}