import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Product = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedVariant, setSelectedVariant] = useState(null);

  const handlePurchaseClick = () => {
    const productDetails = {
      name: `${product.name} ${
        product.variants.length > 0 ? `- ${selectedVariant.name}` : ""
      }`,
      price: selectedVariant.price ? selectedVariant.price : product.basePrice,
      description: product.description,
    };
    localStorage.removeItem("checkoutProduct"); // Clear only checkoutProduct
    localStorage.setItem("checkoutProduct", JSON.stringify(productDetails));
    navigate("/checkout");
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/product/${id}`)
      .then((response) => {
        const result = response.data.data;
        setProduct(result);
        setSelectedVariant(result.variants[0]);
        setLoading(false);
      })
      .catch((error) => {
        const errorMessage =
        error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred while fetching the story.";
        toast.error(errorMessage);
        setLoading(false);
      });
  }, [id]);

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <i className="fa-solid fa-spinner animate-spin text-2xl"></i>
      </div>
    );
  }

  if (!product) {
    return (
      <div className="h-screen flex items-center justify-center">
        <p>No product found</p>
      </div>
    );
  }

  const handleVariantClick = (variant) => {
    setSelectedVariant(variant);
  };

  return (
    <div className="max-w-4xl mx-auto flex items-center justify-center flex-col space-y-2 relative px-4 py-8">
      <div className="flex flex-col md:flex-row items-start md:gap-8">
        <ToastContainer />
        <div className="w-full md:w-1/2">
          <img
            src={product.imageUrl}
            alt={product.name}
            className="w-full rounded-lg"
          />
        </div>
        <div className="w-full md:w-1/2">
          <h2 className="font-semibold">{product.name}</h2>
          <p className="text-xl text-gray-700">
            ${selectedVariant.price.toFixed(2)}
          </p>

          <p
            className="text-gray-600"
            dangerouslySetInnerHTML={{ __html: product.description }}
          />

          <div className="flex flex-wrap gap-2 mb-12">
            {product.variants.map((variant) => (
              <button
                key={variant.name}
                onClick={() => handleVariantClick(variant)}
                className={`text-sm py-2 px-4 rounded-full cursor-pointer ${
                  selectedVariant.name === variant.name
                    ? "bg-black text-white"
                    : "bg-white text-black border border-gray-300"
                }`}
              >
                {variant.name}
              </button>
            ))}
          </div>

          <div className="flex w-full">
            <button
              onClick={handlePurchaseClick}
              className="w-full rounded-md transition-all bg-black px-4 py-2.5 text-white shadow-sm hover:opacity-75 max-w-[300px] text-center"
            >
              Purchase
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
