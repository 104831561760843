import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useCache } from "../../CacheContext";

const ImageCard = ({ _id, images, name, author, company, isFirst }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`cursor-pointer p-4 flex sm:flex-col mb-8 ${
        isFirst ? "flex-col lg:col-span-2 lg:row-span-2 lg:h-auto" : "flex-row"
      }`}
      onMouseEnter={(e) => (e.currentTarget.style.filter = "grayscale(100%)")}
      onMouseLeave={(e) => (e.currentTarget.style.filter = "grayscale(0%)")}
      onClick={() => navigate(`/featured-story/${_id}`)}
    >
      <div className={`${isFirst ? "w-full" : "w-[40%] sm:w-full"}`}>
        <img
          src={images[0]}
          alt={name}
          className={`w-full object-contain sm:object-cover ${
            isFirst
              ? "h-full"
              : "w-full h-auto max-h-[300px] sm:h-full sm:max-h-full"
          }`}
        />
      </div>

      <div className={` ${isFirst ? "w-full" : "w-[60%] sm:w-full"} sm:mr-6 `}>
        <div className={` p-4 sm:-mt-12 sm:ml-6 bg-white`}>
          <p >{name}{'...'}</p>
          <p className="text-sm text-gray-600 font-semibold  m-0">
            {author} -{" "}
            <span className="text-gray-500 font-normal">{company}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

const FeaturedStory = () => {
  const [loading, setLoading] = useState(true);
  const { setCacheData, getCacheData} = useCache();
  const [featuredStories, setFeaturedStories] = useState(() => getCacheData("featuredStories") || []);

  useEffect(() => {
    if (!featuredStories.length) {
      axios
        .get(`${process.env.REACT_APP_BACKEND_URL}/api/public/featured-story`)
        .then((response) => {
          console.log("featured story api call made")
          setFeaturedStories(response.data.data);
          setCacheData("featuredStories", response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          const errorMessage =
            error.response?.data?.message ||
            error.message ||
            "An unexpected error occurred while fetching the featured stories.";
          toast.error(errorMessage);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [featuredStories, setCacheData]);

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <i className="fa-solid fa-spinner animate-spin text-2xl"></i>
      </div>
    );
  }

  if (featuredStories.length === 0) {
    return (
      <div className="h-screen flex items-center justify-center">
        <p>No featured stories found</p>
      </div>
    );
  }

  const columns = [[], [], []];

  featuredStories.forEach((story, index) => {
    columns[index % 3].push(story);
  });

  return (
    <div className="container mx-auto px-4 py-8">
      <ToastContainer />
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 items-start">
        {columns.map((column, colIndex) => (
          <div key={colIndex}>
            {column.map((story, index) => (
              <ImageCard
                key={index}
                {...story}
                isFirst={colIndex === 0 && index === 0}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturedStory;
