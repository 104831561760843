import React from "react";
import ReactDOMClient from "react-dom/client";
import App from "./App";
import "./index.css";
import { BrowserRouter } from "react-router-dom";

const container = document.getElementById("root");

const root = ReactDOMClient.createRoot(container);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
      <App />
  </BrowserRouter>
  // </React.StrictMode>
);
