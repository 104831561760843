import React, { createContext, useState, useEffect } from 'react';
import {jwtDecode} from 'jwt-decode';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const [logoutTimer, setLogoutTimer] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        if (decoded.exp > currentTime) {
          setIsAuthenticated(true);
          const expirationTime = decoded.exp * 1000 - Date.now();
          setLogoutTimer(setTimeout(logout, expirationTime));
        } else {
          localStorage.removeItem('token');
        }
      } catch (error) {
        localStorage.removeItem('token');
      }
    }
    setLoading(false); // Set loading to false after checking token
  }, []);

  const login = (token) => {
    localStorage.setItem('token', token);
    setIsAuthenticated(true);
    const decoded = jwtDecode(token);
    const expirationTime = decoded.exp * 1000 - Date.now();
    setLogoutTimer(setTimeout(logout, expirationTime));
  };

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, loading, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
