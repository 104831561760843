import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useParams } from "react-router-dom";
import { useCache } from "../../CacheContext";
import OverlayImage from "./OverlayImage";

const SingleFeaturedStory = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const { getCacheData, setCacheData } = useCache();
  const [featuredStory, setFeaturedStory] = useState(
    () => getCacheData(`featuredStory_${id}`) || null
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/api/public/featured-story/${id}`
        );
        console.log("single featured story api call made")
        setFeaturedStory(response.data.data);
        setCacheData(`featuredStory_${id}`, response.data.data);
        setLoading(false);
      } catch (error) {
        const errorMessage =
          error.response?.data?.message ||
          error.message ||
          "An unexpected error occurred while fetching the featured story.";
        toast.error(errorMessage);
        setLoading(false);
      }
    };

    if (!featuredStory) {
      fetchData();
    } else {
      setLoading(false);
    }
  }, [id, featuredStory, setCacheData]);

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <i className="fa-solid fa-spinner animate-spin text-2xl"></i>
      </div>
    );
  }

  if (!featuredStory) {
    return (
      <div className="h-screen flex items-center justify-center">
        <p>No featured story found</p>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto flex items-center justify-center flex-col space-y-2 relative px-4 py-8">
      <ToastContainer />
      <Link
              to="/turn-your-story-into-a-film"
              className={`bg-black text-white py-2 px-4 text-center  font-lato transition-all duration-300 fixed bottom-4 left-1/2 transform -translate-x-1/2 z-50 whitespace-nowrap`}
            >
              TURN YOUR STORY INTO A FILM
            </Link>
      <div className="flex flex-col items-center gap-8">
        {/* <img src={featuredStory.logo} className="max-h-[400px]"></img> */}
        <OverlayImage imageUrl={
          featuredStory.images.length > 0 ? featuredStory.images[0] : featuredStory.logo
        } 
        title={featuredStory.author}
        description={featuredStory.company}
        />

        <div dangerouslySetInnerHTML={{ __html: featuredStory.description }} />

        <div>
          <h4 className="text-center">PHOTO</h4>
          {featuredStory.images.map((image) => (
            <img src={image} className="max-h-[500px] mb-4"></img>
          ))}
        </div>

        <div>
          <h4 className="text-center">SHORT FILM</h4>
          {featuredStory.videos.map((video) => (
            <video
              controls
              autoPlay
              muted
              playsInline
              preload="auto"
              className="max-h-[600px] mb-4"
            >
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SingleFeaturedStory;
