import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import LoginModal from "../components/Modal/LoginModal";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, loading } = useContext(AuthContext);

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <i className="fa-solid fa-spinner animate-spin"></i>
      </div>
    ); // Show loading state while checking authentication
  }

  // return isAuthenticated ? children : <Navigate to="/login" />;
  return isAuthenticated ? children : <LoginModal />;
};

export default ProtectedRoute;
